/* eslint-disable @nx/enforce-module-boundaries */
import { ChevronDownIcon } from '@chakra-ui/icons';
import { CirclePlus, Search } from '@mybridge/icons';
import { SearchIcon } from '@mybridge/icons/Search';
import {
  Button,
  Checkbox,
  CheckboxGroup,
  FormControl,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Radio,
  Box,
  RadioGroup,
  Spinner,
  Stack,
  Text,
  VStack,
} from '@mybridge/ui';
import { useRouter } from 'next/router';
import { stringify } from 'querystring';
import { forwardRef, useContext, useEffect, useState, useRef } from 'react';

import { Controller, useForm } from 'react-hook-form';
import { CityStateCountryDropdown } from 'v4/components/city-state-country-dropdown';
import { CompaniesFilter } from 'v4/components/companies-filter';
import {
  EMPLOYMENT_CHOICES,
  EMPLOYMENT_EXPERIENCE_LEVEL_FILTERS,
} from 'v4/lib/data';
import { useCurrentLocation } from 'v4/lib/hooks/use-location';
import { JobsListingContext } from '../context';
import { FilterPopover } from 'v4/components/filter-popover/index';
import { LocationIcon } from '@mybridge/icons';
import { useDisclosure } from '@mybridge/ui';
import { LocationsFilter } from './locations';
import _ from 'lodash';
import { SearchFilterLocations } from 'v4/components/filters/locations';
import { isMobile } from 'react-device-detect';
import { SearchContext } from 'v4/snippets/search/context';
import { useSelector, useDispatch } from 'react-redux';
import RecentSearchListBox from 'v4/components/header/recent-search-box';
import {
  getRecentSearch,
  deleteRecentSearchID,
} from 'v4/store/actions/global.data.actions';
import styles from './index.module.scss';

export const JobsListingSearchBar = forwardRef(({ ...props }, ref) => {
  const { push } = useRouter();
  const dispatch = useDispatch();
  const { recentSearches, loading: searchLoading } = useSelector(
    (state) => state.globalData
  );
  const { loggedIn } = useSelector((state) => state.user);
  

  const {
    search,
    setSearch,
    setActiveTab,
    refetchJobsSearch,
    jobsFilters,
    setJobsFilters,
  } = useContext(SearchContext);

  const { countryCode } = useCurrentLocation({});

  const onFilterChange = (filter) => {
    setJobsFilters({ ...(jobsFilters ?? {}), ...(filter ?? {}) });
  };

  const mappingLocation = (item) => {
    if (item.location !== '') {
      return item.location;
    } else {
      return item?.country !== null
        ? { city: item?.city, state: item?.state, country: item?.country }
        : '';
    }
  };

  useEffect(() => {
    refetchJobsSearch();
  }, [search]);

  useEffect(() => {
    if (!recentSearches === 0 && loggedIn) dispatch(getRecentSearch());
  }, [loggedIn]);

  const handleRecentDelete = () => {
    const payload = { id: 'all' };
    dispatch(deleteRecentSearchID(payload)).then((res) => {
      if (res) dispatch(getRecentSearch());
    });
  };

  const handleSearchKeyDown = (e) => {
    if (e.keyCode === 13) {
      const val = e?.target?.value;
      setSearch(val);
      push('/g/jobs?query=' + e?.target?.value);
      setActiveTab?.('jobs');
      // refetchJobsSearch();
    }
  };

  const handleRecentSearch = (e) => {
    const val = e;
    setSearch(val);
    setActiveTab?.('jobs');
    if (val?.length) {
      push('/g/jobs?query=' + e);
    } else {
      push('/');
    }
  };

  return (
    <>
      <Stack
        direction={['column', 'row']}
        flex={1}
        alignItems="stretch"
        justifyContent="stretch"
      >
        <Stack direction={['column', 'row']} flex={1} p={[2, 0]}>
          {recentSearches && recentSearches?.count > 0 ? (
            <>
              <Popover>
                <PopoverTrigger>
                  <FormControl width="100%">
                    <InputGroup>
                      <InputLeftElement>
                        <SearchIcon />
                      </InputLeftElement>
                      <Input
                        // {...register('query')}
                        placeholder="Search by title, skill or company"
                        pl={12}
                        defaultValue={search}
                        borderRadius="full"
                        bg="brandGray.500"
                        onKeyDown={handleSearchKeyDown}
                      />
                    </InputGroup>
                  </FormControl>
                </PopoverTrigger>
                <Portal>
                  <PopoverContent width={"275px"} className={styles.searchPopUp}>
                    <PopoverBody style={{ outline: 'none', borderRadius: '80px', padding: "0" }}>
                      <FormControl width="100%">
                        <InputGroup>
                          <InputLeftElement>
                            <SearchIcon />
                          </InputLeftElement>
                          <Input
                            // {...register('query')}
                            placeholder="Search by title, skill or company"
                            pl={12}
                            defaultValue={search}
                            borderRadius="full"
                            bg="transparent"
                            border="0"
                            onKeyDown={handleSearchKeyDown}
                          />
                        </InputGroup>
                      </FormControl>
                      <Box className={styles.searchPopUpBottomLine} />
                      <VStack align="stretch" className={styles.searchGroup}>
                        <HStack justifyContent="space-between">
                          <Text fontSize="14px" color="black" fontWeight="600">
                            Recent
                          </Text>
                          <Button
                            variant="transparent"
                            size="sm"
                            p={0}
                            onClick={() => handleRecentDelete()}
                          >
                            Clear Searches
                          </Button>
                        </HStack>
                        {!searchLoading ? (
                          recentSearches &&
                          recentSearches?.results?.map((recent, i) => (
                            <RecentSearchListBox
                              key={i}
                              recent={recent}
                              onClick={() => handleRecentSearch(recent?.query)}
                              cursor="pointer"
                            />
                          ))
                        ) : (
                          <HStack justifyContent="center">
                            <Spinner />
                          </HStack>
                        )}
                      </VStack>
                    </PopoverBody>
                  </PopoverContent>
                </Portal>
              </Popover>

            </>
          ) : (
            <>
              <FormControl width="100%">
                <InputGroup>
                  <InputLeftElement>
                    <SearchIcon />
                  </InputLeftElement>
                  <Input
                    // {...register('query')}
                    placeholder="Search by title, skill or company"
                    pl={12}
                    defaultValue={search}
                    borderRadius="full"
                    bg="brandGray.500"
                    onKeyDown={handleSearchKeyDown}
                  />
                </InputGroup>
              </FormControl>
            </>
          )}

          <FormControl>
            {/* <Controller
                  render={({ field }) => (
                    <CityStateCountryDropdown
                      countryCode={countryCode}
                      placeholder="Search"
                      bg="brandGray.500"
                      {...field}
                      borderRadius="full"
                    />
                  )}
                  // control={control}
                  name="location"
                  defaultValue={filters?.location}
                /> */}
            <CityStateCountryDropdown
              countryCode={countryCode}
              placeholder="City, state, or code"
              bg="brandGray.500"
              onChange={(v) => onFilterChange(v)}
              // {...field}
              borderRadius="full"
            />
          </FormControl>
        </Stack>
      </Stack>

      {/* {searchJobsListingPages?.pages?.[0]?.results?.length > 0 ? ( */}

      {/* </form> */}
    </>
  );
});
