/* eslint-disable @nx/enforce-module-boundaries */
'use client';
import { Box, Container, HStack, VStack, Text } from '@mybridge/ui';
import React from 'react';
import styles from './footer.module.scss';
import NextLink from 'next/link';

const StaticPageFooter = () => {
  return (
    <Box className={styles.StaticPageFooter}>
      <Container maxW="1366px" w={['100%','100%']}>
        <VStack>
          <HStack gap={3}>
            <NextLink href="/about-us" passHref>
              <Text fontWeight="500" color="#003049" fontSize={['11px',"16px"]}>
                About Us
              </Text>
            </NextLink>
            <Text>|</Text>
            <NextLink href="/terms-of-use" passHref>
              <Text fontWeight="500" color="#003049" fontSize={['11px',"16px"]}>
                Terms & Conditions
              </Text>
            </NextLink>
            <Text>|</Text>
            <NextLink href="/privacy-policy" passHref>
              <Text fontWeight="500" color="#003049" fontSize={['11px',"16px"]}>
                Privacy Policy
              </Text>
            </NextLink>
            <Text>|</Text>
            <NextLink href="/business" passHref>
              <Text fontWeight="500" color="#003049" fontSize={['11px',"16px"]}>
                For Business
              </Text>
            </NextLink>
          </HStack>
          <Box color="#5B5B5B" fontSize="14px">
            myBridge Corporation © 2024
          </Box>
        </VStack>
      </Container>
    </Box>
  );
};

export default StaticPageFooter;
