/* eslint-disable @nx/enforce-module-boundaries */
import {
  Box,
  Button,
  Card,
  HStack,
  Heading,
  Spinner,
  Stack,
  Text,
  Wrap,
  WrapItem,
} from '@mybridge/ui';
import { forwardRef, useContext, useEffect } from 'react';
import { MyNetworkContext } from 'v4/components/network/context';
import { SuggestedUserCard } from 'v4/components/user-card/suggested';
export const MyNetworkSuggestionsListing = forwardRef(({ ...props }, ref) => {
  const {
    suggestions,
    refetchSuggestions,
    suggestionsIsFetching,
    suggestionsIsLoading,
  } = useContext(MyNetworkContext);

  useEffect(() => {
    refetchSuggestions?.();
  }, []);

  return (
    <>
      <Stack spacing={3}>
        {suggestionsIsFetching || suggestionsIsLoading ? (
          <HStack justifyContent="center">
            <Spinner />
          </HStack>
        ) : (
          <>
            {suggestions?.university?.length ? (
              <>
                <Box p={[0, 2, 4]} pt={[3, 4, 4]}>
                  <HStack justifyContent="space-between">
                    <Heading
                      fontSize="20px"
                      fontWeight="400"
                      color="brandPrimary.500"
                      mb={3}
                    >
                      People you may know from{' '}
                      {suggestions?.university?.[0]?.educations?.[0]?.name ??
                        'similar university'}
                    </Heading>
                    {suggestions?.university?.length > 10 && (
                      <Button variant="transparent">See All</Button>
                    )}
                  </HStack>
                  <Wrap spacing={3}>
                    {suggestions?.university?.map?.((pi, piIndex) => (
                      <WrapItem 
                      maxW={['49%', '30%', '190px']}
                      flexGrow={['1', '1', 0]}
                      flexShrink={['1', '1', 0]}
                      flex="auto"
                       key={piIndex}>
                        <SuggestedUserCard user={pi} />
                      </WrapItem>
                    ))}
                  </Wrap>
                </Box>
              </>
            ) : (
              <></>
            )}

            {suggestions?.location?.length ? (
              <>
                <Box p={[0, 2, 4]} pt={[3, 4, 4]}>
                  <HStack justifyContent="space-between">
                    <Heading
                      fontSize="20px"
                      fontWeight="400"
                      color="brandPrimary.500"
                      mb={3}
                    >
                      People you may know based on your Location
                    </Heading>
                    {suggestions?.location?.length > 10 && (
                      <Button variant="transparent">See All</Button>
                    )}
                  </HStack>
                  <Wrap spacing={3}>
                    {suggestions?.location?.map?.((pi, piIndex) => (
                      <WrapItem minW="160px"
                maxW={['49%', '30%', '250px']}
                flexGrow={['1', '1', 0]}
                flexShrink={['1', '1', 0]} key={piIndex}>
                        <SuggestedUserCard user={pi} />
                      </WrapItem>
                    ))}
                  </Wrap>
                </Box>
              </>
            ) : (
              <></>
            )}

            {suggestions?.follow?.length ? (
              <Box p={[0, 2, 4]} pt={[3, 4, 4]}>
                <HStack justifyContent="space-between">
                  <Heading
                    fontSize="20px"
                    fontWeight="400"
                    color="brandPrimary.500"
                    mb={3}
                  >
                    Trending people in your network to follow
                  </Heading>
                  {suggestions?.follow?.length > 10 && (
                    <Button variant="transparent">See All</Button>
                  )}
                </HStack>
                <Wrap spacing={3}>
                  {suggestions?.follow?.map?.((pi, piIndex) => (
                    <WrapItem maxW={['49%', '30%', '190px']}
                    flexGrow={['1', '1', 0]}
                    flexShrink={['1', '1', 0]}
                    flex="auto" key={piIndex}>
                      <SuggestedUserCard user={pi} />
                    </WrapItem>
                  ))}
                </Wrap>
              </Box>
            ) : (
              <></>
            )}

            {suggestions?.degree?.length ? (
              <>
                <Box p={[0, 2, 4]} pt={[3, 4, 4]}>
                  <HStack justifyContent="space-between">
                    <Heading
                      fontSize="20px"
                      fontWeight="400"
                      color="brandPrimary.500"
                      mb={3}
                    >
                      People you may know based on your Degrees
                    </Heading>
                    {suggestions?.degree?.length > 10 && (
                      <Button variant="transparent">See All</Button>
                    )}
                  </HStack>
                  <Wrap spacing={3}>
                    {suggestions?.degree?.map?.((pi, piIndex) => (
                      <WrapItem maxW={['49%', '30%', '190px']}
                      flexGrow={['1', '1', 0]}
                      flexShrink={['1', '1', 0]}
                      flex="auto" key={piIndex}>
                        <SuggestedUserCard user={pi} />
                      </WrapItem>
                    ))}
                  </Wrap>
                </Box>
              </>
            ) : (
              <></>
            )}

            {suggestions?.role?.length || suggestions?.industry?.length ? (
              <>
                <Box p={[0, 2, 4]} pt={[3, 4, 4]}>
                  <HStack justifyContent="space-between">
                    <Heading
                      fontSize="20px"
                      fontWeight="400"
                      color="brandPrimary.500"
                      mb={3}
                    >
                      People you may know with similar roles and industry
                    </Heading>
                    {suggestions?.role?.length > 10 ||
                    suggestions?.industry?.length > 10 ? (
                      <Button variant="transparent">See All</Button>
                    ) : (
                      <></>
                    )}
                  </HStack>
                  <Wrap spacing={3}>
                    {suggestions?.role?.map?.((pi, piIndex) => (
                      <WrapItem minW="160px"
                maxW={['49%', '30%', '250px']}
                flexGrow={['1', '1', 0]}
                flexShrink={['1', '1', 0]} key={piIndex}>
                        <SuggestedUserCard user={pi} />
                      </WrapItem>
                    ))}
                    {suggestions?.industry?.map?.((pi, piIndex) => (
                      <WrapItem maxW={['49%', '30%', '190px']}
                      flexGrow={['1', '1', 0]}
                      flexShrink={['1', '1', 0]}
                      flex="auto" key={piIndex}>
                        <SuggestedUserCard user={pi} />
                      </WrapItem>
                    ))}
                  </Wrap>
                </Box>
              </>
            ) : (
              <></>
            )}

            {!suggestions?.university?.length &&
            !suggestions?.follow?.length &&
            !suggestions?.degree?.length &&
            !suggestions?.role?.length &&
            !suggestions?.industry?.length &&
            !suggestions?.location?.length ? (
              <HStack jusitifyContent="center">
                <Text>No suggestions for now! Please come back later...</Text>
              </HStack>
            ) : (
              ''
            )}
          </>
        )}
      </Stack>
    </>
  );
});
