/* eslint-disable @nx/enforce-module-boundaries */
import { DegreeConnectionsIcon } from '@mybridge/icons/DegreeConnectionsIcon';
import { FollowersIcon } from '@mybridge/icons/Followers';
import { MutualConnectionIcon } from '@mybridge/icons/MutualConnectionIcon';
import {
  Avatar,
  HStack,
  Link,
  Stack,
  Text,
  AvatarBadge,
  Divider,
  Card,
  VStack,
  Box,
  AvatarGroup,
} from '@mybridge/ui';
import { forwardRef, useMemo, useContext } from 'react';
import {
  formatCommaSeparated,
  formatLocation,
  getUserFullName,
  getUserProfilePic,
} from 'v4/lib/commons';
import { MyNetworkContext } from '../context';
import { useRouter } from 'next/router';
export const MyNetworkUserCardBordered = forwardRef(
  (
    {
      user = {},
      actions,
      children,
      showFollowers,
      avatarProps,
      divider = false,
      ...props
    },
    ref
  ) => {
    const router = useRouter();
    const name = getUserFullName(user);
    const profile_pic = getUserProfilePic(user);
    const {
      position,
      company,
      experiences,
      educations,
      slug,
      degree,
      degree_count,
      mutual_count,
      followers_count,
      matrix_presence,
      profile_banner_pic,
      mutual_connections,
      mutual_connection_count,
    } = user ?? {};
    const networkCtx = useContext(MyNetworkContext);
    const {
      isFollowed,
      isFriend,
      followUser,
      handshakeUser,
      handshakeLoading,
      followLoading,
      isHandshaked,
      setSelectedUser,
    } = networkCtx ?? {};

    const degree_ = degree_count ?? degree;

    const location = useMemo(() => {
      let loc = formatLocation(user);
      return loc?.length ? loc : user?.location;
    }, [user]);

    const MATRIX_PRESENCE = {
      online: {
        bgColor: 'green.500',
        priority: 2,
      },
      busy: {
        bgColor: 'red.500',
        priority: 1,
      },
      offline: {
        bgColor: 'gray.500',
        priority: 0,
      },
    };

    return (
      <>
        <Card
          variant="thinBorderedCard"
          minW="148px"
          maxW="148px"
          w="100%"
          overflow="hidden"
          border="1px solid #D9D9D9"
          boxShadow="0px 0px 10px 0px #0000001a"
          maxH="230px"
          minH="230px"
          gap={0}
        >
          <VStack
            direction={['column', 'row']}
            alignItems="flex-start"
            {...props}
          >
            <HStack w="100%" justifyContent="center" position="relative">
              <Box
                bgImage={
                  profile_banner_pic &&
                  !profile_banner_pic.includes('banner-placeholder1.png')
                    ? profile_banner_pic
                    : '/images/defaultBanner.svg'
                }
                bgPosition="center"
                bgSize="cover"
                position="absolute"
                left="0"
                top="0"
                right="0"
                height="60px"
              ></Box>
              <Avatar
                {...(slug?.length ? { as: Link, href: '/p/' + slug } : {})}
                name={name}
               width="74px"
               height="74px"
                {...(profile_pic?.length ? { src: profile_pic } : {})}
                {...(avatarProps ?? {})}
                mt={2}
                border="2px solid white"
              >
                {/* {isFriend?.(user) && 
            <AvatarBadge bg={...(MATRIX_PRESENCE[matrix_presence]?.bgColor ?? {})} />} */}
              </Avatar>
            </HStack>

            <HStack flex={1} minH="30px">
              <Stack spacing={0} flex={1} pl={2}>
                {name ? (
                  <HStack>
                    <Text
                      
                     
                      fontSize="14px"
                      fontWeight="500"
                      color="brandPrimary.500"
                      textTransform="capitalize"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                      maxW="180px"
                      {...(slug?.length
                        ? { as: Link, href: '/p/' + slug }
                        : {})}
                    >
                      {name}
                    </Text>
                    {/* {followers_count && showFollowers ? (
                      <HStack opacity={0.5}>
                        <FollowersIcon width="20" height="20" />
                        <Text>{followers_count}</Text>
                      </HStack>
                    ) : (
                      ''
                    )} */}
                  </HStack>
                ) : (
                  ''
                )}

                <Box>
                  <Stack minH="30px" mb={1} spacing={0}>
                    {experiences?.length? (
                      <>
                        <Text
                          fontSize="10px"
                          m={0}
                          fontWeight="600"
                          color="#868585"
                          maxW="160px"
                          overflow="hidden"
                          textOverflow="ellipsis"
                          whiteSpace="nowrap"
                        >
                          {experiences?.[0]?.title}
                        </Text>
                        <Text
                          fontSize="10px"
                          m={0}
                          fontWeight="400"
                          color="#868585"
                          maxW="160px"
                          overflow="hidden"
                          textOverflow="ellipsis"
                          whiteSpace="nowrap"
                        >
                          {experiences?.[0]?.company??experiences?.[0]?.company_name??""}
                        </Text>
                      </>
                    ) : educations?.length ? (
                      <Text
                        fontSize="10px"
                        maxW="160px"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                      >
                        {educations?.[0]?.name}
                      </Text>
                    ) : (
                      (
                        <>
                      <Text fontSize="10px">
                        {position?.length>25?position?.slice(0,25)+"...":position}
                      </Text>
                      <Text fontSize="10px" color="#868585">
                      {company}
                    </Text>
                    </>
                      ) ?? ''
                    )}
                    {/* {location ? (
                <Text fontSize="sm" color="blackAlpha.500">
                  {location}
                </Text>
              ) : (
                ''
              )} */}
                  </Stack>

                  {mutual_connections?.length ? (
                    <>
                      <HStack
                        spacing={0}
                        alignItems="flex-start"
                        onClick={() =>
                          router.push(`/profile/network/${user.slug}`)
                        }
                        cursor="pointer"
                        minH={2}
                      >
                        <AvatarGroup color="blackAlpha.500" size="sm" max={3}>
                          {mutual_connections?.map?.((u, uInd) => (
                            <Avatar
                              as={Link}
                              href={'/p/' + u?.slug}
                              key={uInd}
                              width="18px"
                              height="18px"
                              src={getUserProfilePic(u)}
                              name={getUserFullName(u)}
                              border="1px solid #fff"
                              fontSize="8px"
                              marginRight="-5px !important"
                              webkitMarginEnd="none"
                              marginInlineEnd="none"
                            />
                          ))}
                        </AvatarGroup>
                        <Text fontSize="9px" color="#868585" ms="8px" mt="1px">
                          {mutual_connection_count > 3
                            ? `+${mutual_connection_count - 3}`
                            : ''}{' '}
                          shared connections
                        </Text>
                      </HStack>
                    </>
                  ) : (
                     <HStack
                    spacing={0}
                    alignItems="flex-start"
                   
                    minH={"20px"}>

                    </HStack>
                  )}
                </Box>

                {children}
              </Stack>
            </HStack>
            <Stack
              // {...(props?.flexWrap ? { w: '100%' } : {})}
              h="100%"
              w="100%"
              justifyContent="center"
              px={3}
              pb={3}
            >
              {actions}
            </Stack>
          </VStack>
        </Card>
      </>
    );
  }
);
